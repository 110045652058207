<template>
    <v-card
        class="d-flex flex-column mb-7"
        elevation="7"
        height="100%"
    >
        <a :id="contentProp.anchor"></a>
        <v-img
            contain
            max-height="230"
            :src="require('@/assets/' + contentProp.image)"
        />
        <v-card-title>{{contentProp.title}}</v-card-title>
        <v-card-text class="pb-0">
            <v-chip
                v-for="(tag, i) in contentProp.tags"
                :key="i"
                small
                class="mr-1 mb-1"
            >
                {{tag.title}}
            </v-chip>
        </v-card-text>
        <v-card-text class="pb-0" v-html="contentProp.text"></v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions v-if="contentProp.link != null">
            <v-btn
                color="secondary"
                block
                :href="contentProp.link"
                target="blank"
            >
                Website besuchen
                <v-icon dark right>mdi-launch</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "InfoCardVertical",
    props: {
        contentProp: Object
    },
    data: () => ({
        //
    })
};
</script>
